<template>
  <div class="MobileAppAdvert">
    <div class="MobileAppAdvert__banner">
      <img src="../assets/phone.png" height="600"/>
    </div>
    <div class="MobileAppAdvert__description">
      <h1>{{$ml.get('mobileAppAdvert.header')}}</h1>
      <p>{{$ml.get('mobileAppAdvert.description')}}</p>
      <!-- <h2>Coming soon</h2> -->
      <span>
        <a href="https://apps.apple.com/app/vegan-kind/id1673486722" target="_blank">
          <img src="../assets/appStore.png" height="50"/>
        </a>
      </span>
      <span>
        <a href="https://play.google.com/store/apps/details?id=vegankind.com" target="_blank">
          <img src="../assets/googlePlay.png" height="50"/>
        </a>
      </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'MobileAppAdvert'
}
</script>

<style scoped lang="stylus">
.disabled
  opacity 0.1

.MobileAppAdvert
  display flex
  background white
  align-items flex-start
  overflow-y auto
  justify-content center
  height 100%
  flex-wrap wrap
  padding 50px 0 0 0
  box-sizing border-box
  &__banner
    display inline-block
  &__description
    text-align left
    max-width 450px
    display inline-block
    box-sizing border-box
    color #555
    font-size 14px
    padding 70px 0 0 70px
    h1
      font-family: 'Bebas Neue', sans-serif;
      font-size 32px
      font-weight 300
      color black
    h2
      font-family Helvetica
    span
      margin-right 15px
      img
        margin-top 80px
        margin-bottom 20px

@media screen and (max-width: 868px)
  .MobileAppAdvert
    padding 30px 5px
    &__description
      padding 30px 0 0 20px
</style>
